import "core-js/stable";
import "regenerator-runtime/runtime";
import "shared/sentry";
import Alpine from "alpinejs";
import collapse from '@alpinejs/collapse'

import "shared/unpoly";
import "shared/autosize";
import "shared/active_storage";
import "shared/uppy";
import "shared/flash";
import "shared/clipboard";
import "shared/poll";
import "pmls/styles/main.css";
import "pmls/autosave";
import "pmls/alpinejs_helpers"
import calcItemsSum from "pmls/calcItemsSum";
import formatCurrency from "pmls/formatCurrency";
import setupPackageDateRangePicker from "pmls/setupPackageDateRangePicker";
import alpinejs_helpers from "../pmls/alpinejs_helpers";
import "cocoon-js-vanilla";

import { PDFItemAnnotator } from 'pmls/pdf_item_annotator';

up.network.config.badResponseTime = 500;

let reloading = false;
const reloadBadges = () => {
  if (reloading) return;
  if (document.hidden) return;
  if (!up.element.get("#admin-nav-links")) return;

  reloading = true;
  up.replace("#admin-nav-links", "/" + document.location.pathname.split('/')[1], {
    history: false,
    restoreScroll: true,
    cache: false,
  }).then(() => {
    reloading = false;
  });
}
// setInterval(reloadBadges, 150000)

up.macro("[up-remote]", link => {
  link.setAttribute('up-target', '#body')
  link.setAttribute('up-scroll', 'restore')
  link.setAttribute('up-history', 'false')
})

up.compiler('[data-cocoon]', el => {
  el.addEventListener('cocoon:after-insert', (event) => {
  })
  el.addEventListener('cocoon:after-remove', (event) => {
    el.dispatchEvent(new Event('coconremove'));
  }) 
})

up.compiler('#items', el => {
  el.addEventListener('cocoon:after-insert', (event) => {
    let inputs = event.target.querySelectorAll('input[data-behavior~=supplier-input]')
    let lastNonEmpty = Array.from(inputs).reverse().find(i => (i.value != ""))
    if (lastNonEmpty != null) {
      let inserted = event.detail[2]
      inserted.querySelector('input[data-behavior~=supplier-input]').value = lastNonEmpty.value
    }
  })
  el.addEventListener('cocoon:before-remove', (event) => {
    let removedElement = event.detail[0];
    Array.from(removedElement.getElementsByTagName('input')).forEach(element => {
      element.removeAttribute("required");
    })
    Array.from(removedElement.getElementsByTagName('textarea')).forEach(element => {
      element.removeAttribute("required");
    })
  })
})

up.compiler("[mup-follow-stop-propagation]", el => {
  el.addEventListener('click', (e) => {
    up.follow(el);
    e.stopPropagation();
  });
});

up.compiler("[data-href]", el => {
  el.addEventListener('click', (e) => {
    if (!['button', 'a'].includes(e.target.tagName.toLowerCase())) {
      const url = el.dataset.href
      if (e.ctrlKey || e.metaKey) window.open(url, "_blank")
      else document.location.href = url
    }
  })
})

up.on('up:layer:opened', el => {
  if (!el.layer.element.classList.contains('pmls-modal')) {
    el.layer.element.classList.add('custom-modal')
  }
  let modal = el.layer.element.querySelector('[up-main="modal"]')
  if (modal) {
    let classes = modal.getAttribute('data-up-modal-classes')
    if (classes) {
      el.layer.element.classList.add(classes)
    }
  }
})

up.compiler("[data-up-bs-modal-dismiss]", el => {
  el.addEventListener('click', (event) => {
    let modalEl = el.closest('.modal');
    
    if (el.dataset.upBsDismissLayer) {
      if (up.layer.current.mode == 'modal') {
        up.layer.current.dismiss();
      }
    }
    let modal = bootstrap.Modal.getOrCreateInstance(modalEl);
    if (modal._isShown != true) {
      modal._isShown = true
    }
    modalEl.addEventListener('hidden.bs.modal', () => {
      modal.dispose();
      modalEl.remove();
    }, {once:true});
    modal.hide();
  })
})

window.calcItemsSum = calcItemsSum;
window.formatCurrency = formatCurrency;
window.setupPackageDateRangePicker = setupPackageDateRangePicker;
window.PDFItemAnnotator = PDFItemAnnotator;
window.$pmls = alpinejs_helpers;

window.Alpine = Alpine;
document.addEventListener("DOMContentLoaded", function(event) {
  Alpine.plugin(collapse);
  Alpine.start()
});
