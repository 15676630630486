/*
type AdobeDCType = {
  View: 
}

declare global {
  interface Window {
    AdobeDC: AdobeDCType; // Replace 'any' with the actual type you want to assign
  }
}
*/

declare namespace window {
  namespace AdobeDC {
    type Content = {
      location: { url: string } 
    }

    type SearchAPI = {
      search(text: string): Promise<void>;
    }

    type API = SearchAPI;
    type MetaData = {
      fileName: string, 
      id: string
    }
    type PreviewFileOptions = {
      defaultViewMode?: "FIT_WIDTH" | "FIT_PAGE" | "TWO_COLUMN" | "TWO_COLUMN_FIT_PAGE" | "CONTINUOUS" | "SINGLE_PAGE",
      
      enableFormFilling?: boolean,
      showDownloadPDF?: boolean,
      showPrintPDF?: boolean,
      exitPDFViewerType?: "CLOSE"|"RETURN",
      showThumbnails?: boolean,
      showBookmarks?: boolean,
      enableLinearization?: boolean,
      enableAnnotationAPIs?: boolean,
      includePDFAnnotations?: boolean,
      enableSearchAPIs?: boolean,
      showDisabledSaveButton?: boolean,
      focusOnRendering?: boolean,
      showFullScreenViewButton?: boolean,
    }
    type AnnotationEventData = {
      ["@context"]: string[];
      bodyValue: string;
      created: string;
      creator: {
        type: string;
        name: string;
      };
      id: string;
      modified: string;
      motivation: string;
      target: {
        source: string;
        selector: any;
      }
      type: string;
    }
    type EventTypes = "ANNOTATION_ADDED"|"ANNOTATION_SELECTED";
    type Event = {
      type: EventTypes;
      data: AnnotationEventData;
    }
    type RegisterEventOptions = {
      listenOn: EventTypes[]
    }
    class AnnotationManager {
      registerEventListener(callback: (event: Event) => void, options: RegisterEventOptions): void;
      addAnnotations(annotations: object[]): Promise<void>;
    }
    class PreviewFile {
      getAnnotationManager(): Promise<AnnotationManager>;

      getAPIs(): Promise<API>;
    }
    class View {
      constructor(options: { clientId: string; divId: string });
      
      previewFile(
        { content, metaData}: {  content: Content, metaData: MetaData},
        options: PreviewFileOptions
      ): Promise<PreviewFile>;
    }
  }
}

export class AdobePDFViewer {
  adobeDCView: window.AdobeDC.View;
  preview?: window.AdobeDC.PreviewFile;
  annotationManager?: window.AdobeDC.AnnotationManager;
  title?: string;
  url?: string;
  previewWaiters: ((preview: window.AdobeDC.PreviewFile) => void)[] = [];
 
  constructor(api_key: string, div_id: string) {
    this.adobeDCView = new window.AdobeDC.View({clientId: api_key, divId: div_id});
  }

  async open(url: string, title: string) {
    this.title = title;
    this.url = url;
    console.log(" => x", url, title, this.title);
    const preview = await this.adobeDCView.previewFile({ content:   {location: {url}}, metaData: {fileName: title, id: title} }, 
      {defaultViewMode: "FIT_WIDTH",enableAnnotationAPIs: true, enableSearchAPIs: true}
    );
    this.preview = preview;
    this.annotationManager = await this.preview.getAnnotationManager()
    this.previewWaiters.forEach((callback) => callback(preview));
    this.previewWaiters = [];
  }

  async waitForPreview(callback: (preview: window.AdobeDC.PreviewFile) => void) {
    if (this.preview) {
      callback(this.preview);
    } else {
      this.previewWaiters.push(callback);
    }
  }

  async waitForAnnotationManager(callback: (annotationManager: window.AdobeDC.AnnotationManager) => void) {
    if (this.annotationManager) {
      callback(this.annotationManager);
    } else {
      this.waitForPreview(async (preview) => {
        callback(this.annotationManager ?? await preview.getAnnotationManager());
      });
    }
  }

  static onLoaded(load: () => void) {
    if (window.AdobeDC) {
      load()
    } else {
      document.addEventListener("adobe_dc_view_sdk.ready", load)
    }
  }
  
};