import Toastify from "toastify-js";

up.compiler("[data-clipboard]", (el) => {
  var clipboard = new ClipboardJS(el, {
    text: (trigger) => (
      trigger.getAttribute('data-clipboard')
    )
  });

  clipboard.on('success', function(e) {
    Toastify({
      text: "Copied!",
      duration: 1000,
      gravity: "top",
      positionLeft: false,
      backgroundColor: `var(--secondary-500)`,
      stopOnFocus: false
    }).showToast();
    e.clearSelection();
  });

  clipboard.on('error', function(e) {
    Toastify({
      text: "Failed to copy!",
      duration: 1000,
      gravity: "top",
      positionLeft: false,
      backgroundColor: `var(--red-500)`,
      stopOnFocus: false
    }).showToast();
  });
})
