import Toastify from "toastify-js";

up.compiler("[data-flash]", (element, flashes) => {
  const colors = {
    info: "blue",
    success: "green",
    notice: "blue",
    alert: "orange",
    error: "red"
  }

  flashes.forEach(([key, message]) => {
    Toastify({
      text: message,
      duration: 5000,
      gravity: "top",
      positionLeft: false,
      backgroundColor: `var(--${colors[key]}-500)`,
      stopOnFocus: true
    }).showToast();
  })
});