import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

function getRailsEnvironment() {
  let {host} = window.location;
  if (host.endsWith('mymalls.com')) {
    if (host.startsWith('staging-')) {
      return 'staging';
    } else {
      return 'production';
    }
  } else {
    return 'development';
  }
}

Sentry.init({
  dsn: 'https://cba27e1605464e35954a98cc7b085a31@o1018786.ingest.sentry.io/5984729',

  environment: getRailsEnvironment(),
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "my-malls-web",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.025,
});
window.Sentry = Sentry;
