import { AdobePDFViewer } from './adobe_pdf_viewer';
import * as Toastify from "toastify-js";

type PDFLocation = {boundingBox: number[], pageNumber: number, invoiceId: number};
type ItemInfo = {item_id: number};

export class PDFItemAnnotator {
  viewer?: AdobePDFViewer;
  package_code?: string;

  constructor(api_key: string, div_id: string, url: string, title: string, 
    { user_annotation_event, pmls_annotation_selected, package_code }: { 
      user_annotation_event : (event: any) => void, package_code: string, 
      pmls_annotation_selected: (event: any) => void
    }) {
    AdobePDFViewer.onLoaded(async () => {
      let viewer = new AdobePDFViewer(api_key, div_id);
      this.viewer = viewer;
      this.package_code = package_code;
      await viewer.open(url, title);
      viewer.annotationManager?.registerEventListener((event) => {
        console.log(" => event", event);
        console.log(" => p", user_annotation_event, pmls_annotation_selected, title);
        if (event.type == "ANNOTATION_ADDED") {
          if (event.data.creator.name === "PMLS") return;
          user_annotation_event(event)
        } else if (event.type == "ANNOTATION_SELECTED") {
          if (event.data.creator.name !== "PMLS") return;
          pmls_annotation_selected(event)
        }
       }, {listenOn: ["ANNOTATION_ADDED", "ANNOTATION_SELECTED"]});
    });
  }

  async showPDFLocations(pdf_locations: [number,string|PDFLocation|null][], selectedInvoice: number) {
    console.log(" => pdf_locations", pdf_locations, selectedInvoice)
    let locations = pdf_locations.map(([item_id,location], idx) => { 
      if (typeof location === 'string') {
        if (location === "") {
          return { location: null, idx, item_id: null };
        }
        return { location: JSON.parse(location) as PDFLocation, idx, item_id };
      } else {
        return { location, idx, item_id };
      }
    }).filter(({ location, idx, item_id }) => location !== null) as {location: PDFLocation, idx: number, item_id: number }[];
    await this.showAnnotations(locations.filter(({location, idx, item_id}) => (Number(location.invoiceId) === Number(selectedInvoice))));
  }

  async search(text: string) {
    await this.viewer?.waitForPreview(async (preview) => {
      const api = await preview.getAPIs();
      try {
        await api.search(text);
      } catch (error) {
        Toastify({
          text: "Not found in document",
          duration: 1000,
          gravity: "top",
          position: "right",
          backgroundColor: "var(--red-500)",
          stopOnFocus: true
        }).showToast();
      }
    });
  }

  async showAnnotations(annotations: {location: {boundingBox: number[], pageNumber: number}, idx: number, item_id: number}[]) {
    annotations.forEach(annotation => {
      console.log(" ++ +> annotation", annotation);
      this.showAnnotation(annotation.location, annotation.idx, annotation.item_id);
    });
  }
  
  async showAnnotation(annotation: {boundingBox: number[], pageNumber: number}, idx: number, item_id: number) {
    const annotations = [
      {
        "@context": [
          "https://www.w3.org/ns/anno.jsonld",
          "https://comments.acrobat.com/ns/anno.jsonld"
          ],
          "type": "Annotation",
          "id": `${this.package_code}-${idx + 1}-${item_id}`,
          "bodyValue": `${this.package_code} - ${idx + 1}`,
          "motivation": "commenting",
          target: {
            "source": this.viewer?.title,
            "selector": {
              "node": {
                "index": annotation.pageNumber
              },
              "opacity": 0.25,
              "subtype": "highlight",
              "boundingBox": annotation.boundingBox,
              "quadPoints": [
                    annotation.boundingBox[0], annotation.boundingBox[3],
                    annotation.boundingBox[2], annotation.boundingBox[3],
                    annotation.boundingBox[0], annotation.boundingBox[1], 
                    annotation.boundingBox[2], annotation.boundingBox[1], 
              ],
              "strokeColor": "#FFFF00",
              "strokeWidth": 1,
              "type": "AdobeAnnoSelector"
            },
        },
        "creator": {
          "type": "Person",
          "name": "PMLS"
        },
        "created": new Date().toJSON(),
        "modified": new Date().toJSON()
      }
    ];
    console.log("=> annotations:",annotations);
    await this.viewer?.waitForAnnotationManager(annotationManager => annotationManager.addAnnotations(annotations))
  }
}