export default function calcItemsSum() {
  let values = [...body.querySelectorAll('[data-behavior~=item-root')].map (
    (i) => {
      if (i.offsetParent === null) {
        return 0;
      } else {
        return i.querySelector('[data-behavior~=item-quantity]').value * i.querySelector('[data-behavior~=item-value]').value
      }
    }
  )
  let result = values.reduce((total, num) => (total + num), 0);
  return result;
}