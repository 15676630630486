
function removeTrailingWhitespaces(str) {
    return str.replace(/\s+$/, '');
}

export default {
    extractCampaigns(value, all_campaigns) {
        let campaigns = [];
        all_campaigns.filter(campaign_name => campaign_name != "").forEach(campaign_name => {
            if (value.toLowerCase().includes(campaign_name)) {
                campaigns.push(campaign_name);
            }
        });
        return campaigns;
    },
    updateDescription(description, checked, campaign, campaigns) {
        if (campaign == "") {
            return description;
        }
        if (checked) {
            if (!description.endsWith(`#${campaign}`)) {
                description = removeTrailingWhitespaces(description) + ` #${campaign}`;
            }
        } else {
            if (description.endsWith(`#${campaign}`)) {
                description = removeTrailingWhitespaces(description.slice(0,-(campaign.length+1)));
            }
        }
        return description;
    },
    canChangeCampaign(description, campaign_name, campaigns) {
        if (campaign_name == "") {
            return false;
        }
        if (campaigns.includes(campaign_name)) {
            return description.endsWith(`#${campaign_name}`) && !description.slice(0,-1).includes(campaign_name);
        } else {
            return true;
        }
    }
}