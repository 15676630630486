up.compiler('[data-poll]', function(element) {
  const interval = parseInt(5000);
  const timer = setInterval(function() {
    up.reload(element, {
      restoreScroll: true,
      history: false,
    })
  }, interval);
  return function() { clearInterval(timer) } // stop polling when element is removed
});


up.compiler('[data-poll-backoff][up-keep]', function(element) {
  let minIncrease = 300;
  let maxTime = 10000;
  let state = {
    stopped: false,
    actualTime: 400,
    timer: null,
    id: new Date().getTime(),
  }

  function poll() {
    if (state.stopped) {
      return;
    }
    state.timer = setTimeout(
      async function () {
        let reloadStart = new Date().getTime();
        let url = element.dataset.pollUrl;
        try {
          await up.reload(element, {
            restoreScroll: true,
            history: false,
            url
          })
        } catch (error) {}
        let reloadEnd = new Date().getTime();
        let reloadTime = reloadEnd-reloadStart;
        state.actualTime = Math.min(state.actualTime + Math.max(reloadTime, minIncrease), maxTime);
        poll();
      },
      state.actualTime
    );
  }

  poll();

  return function() { clearTimeout(state.timer); state.timer = null; state.stopped = true; } // stop polling when element is removed
});