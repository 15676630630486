export default function setupPackageDateRangePicker(el, startDate, endDate, onUpdate) {
  let start = startDate ? moment(startDate) : moment().subtract(29, 'days');
  let end = endDate ? moment(endDate) : moment();

  $('#' + el).daterangepicker({
    startDate: start,
    endDate: end,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  });

  $('#' + el).on('apply.daterangepicker', function(ev, picker) {
    let field = document.getElementById(el);
    let start = picker.startDate
    let end = picker.endDate;
    field.querySelector('span').textContent = start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY');
    field.querySelector('.data-range-start').value = start.format('YYYY-MM-DD');
    field.querySelector('.data-range-end').value = end.format('YYYY-MM-DD');
    onUpdate({start,end});
  });
}
