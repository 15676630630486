import throttle from 'lodash.throttle';

up.compiler('[data-autosave-form]', function(element) {
  let second = 1000;
  let onFormChange = throttle(async () => {
    var alert = document.getElementById("restore-autosave-alert");
    if (alert) {
      alert.classList.add("hidden");
    }
  
    let params = {autosave: "true"};
    let options = up.form.submitOptions(element, {target: 'div#autosave-output', params: params, history: false, navigate: false});
    let response = await up.request(options.url, {params: options.params, method: options.method});
    if (response.status != 200) {
      console.log("# [autosave] Unexpected response:", response);
    }
  }, 20 * second);
  up.observe(element, onFormChange);
  $('#items').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    up.observe(element, onFormChange);
  });
});
