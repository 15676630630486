import Toastify from "toastify-js"
import Uppy from "@uppy/core"
import Spanish from "@uppy/locales/lib/es_ES"
import French from "@uppy/locales/lib/fr_FR"
import DragDrop from "@uppy/drag-drop"
import Dashboard from "@uppy/dashboard"
import Webcam from '@uppy/webcam'
import ActiveStorageUpload from "./uppy-activestorage-upload"
import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "suite/images/pdf.png"

function localeFromLanguage(lang) {
  if (lang == 'es') {
    return Spanish
  } else if (lang == 'fr') {
    return French
  }
  return undefined
}


up.compiler("[data-uploader-photo]", el => {

  const {lang} = document.documentElement;
  const form = el.closest("form");

  const {inputField} = el.dataset;
  const file_input = form.querySelector(inputField);
  console.log("form", form);
  console.log("file-input", file_input);

  function insertSignedIdHiddenField(form, input, signed_id) {
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("value", signed_id)
    hiddenField.name = input.name
    form.appendChild(hiddenField)
  }

  const uppy = new Uppy({
    debug: true,
    locale: localeFromLanguage(lang),
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxFileSize: 10 * 1024 * 1024,
      allowedFileTypes: [
            "image/*"
        ]}})
        .use(ActiveStorageUpload, {
          directUploadUrl: el.dataset.activeStorageUploader,
        })
        .use(Dashboard, { inline: true, target: el })
        .use(Webcam, {target: Dashboard});
    uppy.on('complete', (result) => {
      result.successful.forEach( upload => {
        insertSignedIdHiddenField(form, file_input, upload.response.signed_id)
      });
    });

})

up.compiler("[data-uploader]", el => {
  const {
    uploaderFileTypes,
    uploaderInline,
    uploaderNote,
    uploaderTrigger,
    uploaderSubmitUrl,
    uploaderSubmitMethod = "post",
    uploaderTarget = "#body",
    uploaderNewLayout = false,
    uploaderText = null,
    uploaderEvent = null
  } = el.dataset

  let eventTarget = null
  if (uploaderEvent) {
    eventTarget = el.parentElement.parentElement
  }

  document.addEventListener("dragover", e => {
    if (uploaderInline === "true") return
    const dashboard = uppy.getPlugin("Dashboard")
    if (!dashboard.isModalOpen()) {
      dashboard.openModal()
    }
  })

  const {lang} = document.documentElement;

  const uppy = new Uppy({
    debug: true,
    locale: localeFromLanguage(lang),
    autoProceed: true,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxFileSize: 10 * 1024 * 1024,
      allowedFileTypes: uploaderFileTypes
        ? uploaderFileTypes.split(" ")
        : [
            "image/*",
            ".pdf",
            ".html",
            ".doc",
            ".docx",
            ".xls",
            ".pages",
            ".numbers",
            ".csv",
            ".rtf",
          ],
    },
  })
    .use(ActiveStorageUpload, {
      directUploadUrl: el.dataset.uploader,
    })
    .use(DragDrop, {})
    .use(Dashboard, {
      target: el,
      trigger: uploaderTrigger || "[data-uploader-trigger]",
      inline: uploaderInline === "true",
      replaceTargetContent: true,
      showProgressDetails: true,
      closeAfterFinish: uploaderInline !== "true",
      animateOpenClose: false,
      proudlyDisplayPoweredByUppy: false,
      note: uploaderNote,
      height: el.dataset.uploaderHeight ? Number(el.dataset.uploaderHeight) : 260,
      width: 'auto',
      locale: {
        strings: {
          browseFiles: el.dataset.uploaderBrowseFiles || "Browse",
          dropPasteFiles: el.dataset.uploaderDropPasteFiles ? `${el.dataset.uploaderDropPasteFiles} %{browseFiles}` : "Drop files here paste or %{browseFiles}",
          dropPasteImportFiles: el.dataset.uploaderDropPasteFiles ? `${el.dataset.uploaderDropPasteFiles} %{browseFiles}` : "Drop files here paste or %{browseFiles}"
        }
      },
      metaFields: [
        { id: "name", name: "Name", placeholder: "file name" },
        {
          id: "caption",
          name: "Caption",
          placeholder: "describe what the image is about",
        },
      ]
    })
  uppy.on("complete", result => {
    up.element.get("body").classList.remove("uppy-Dashboard-isFixed")
    up.render(uploaderTarget, {target: uploaderTarget, url: uploaderSubmitUrl,
      method: uploaderSubmitMethod,
      params: Object.assign(
        ...result.successful.map((file, i) => ({
          [`files[${i}][signed_id]`]: file.response.signed_id,
          [`files[${i}][title]`]: file.name,
        }))
      ),
    })
      .then((result) => {
        if (uploaderEvent != null) {
          eventTarget.dispatchEvent(new CustomEvent(uploaderEvent, {bubbles: true}))
        }
      })
      .catch((error) => {
        console.log("Upload faliled",error);
        Toastify({
          text: "Upload failed. Please try again.",
          duration: 3000,
          gravity: "top",
          positionLeft: false,
          backgroundColor: `var(--red-500)`,
          stopOnFocus: false,
        }).showToast()
      })
  })
})
