import "unpoly";
import "unpoly/unpoly-migrate.js"
import "unpoly/unpoly.css";

up.network.config.badResponseTime = 2000;
up.motion.config.duration = 150;
up.network.config.progressBar = true;

up.compiler("[up-slow]", element => {
  const show = () => {
    up.element.show(element);
  };
  const hide = () => {
    up.element.hide(element);
  };

  hide();

  return [up.on("up:network:late", show), up.on("up:network:recover", hide)];
});

document.addEventListener('up:proxy:loaded', () => {
  up.emit("up:proxy:recover");
})